<template>
  <div class="result">
    <div class="result_img">
      <img class="result_img_check" src="../../image/icon_correct.png" alt="">
    </div>
    <span class="result_title">报名成功！</span>
    <span class="result_info">* 请您按时前往报名场地参与活动。</span>
    <div class="result_img">
      <img class="result_img_body" src="../../image/finish.png" alt="finish">
    </div>
  </div>
</template>

<script>
export default {
  name: "result",
    mounted() {
      setTimeout( ()=>{
          this.$router.replace({name:'register.information'})
      }, 1500)
    },
    methods:{

    }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
@import '../../styles/mixins.styl'

.result
  padding-top  20px
  .result_img
    display flex
    align-items center
    justify-content center
    margin-top  24px
    margin-bottom  46px
    .result_img_check
      width 150px
      background $theme
      border-radius 50%
    .result_img_body
      width 601px
      height 267px
  .result_title
    text-align center
    display block
    font-size 72px
    font-family Source Han Sans CN;
    font-weight bold
    color $theme
    line-height 69px
    padding 40px 0
  .result_info
    text-align center
    display block

</style>
